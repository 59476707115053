<template>
  <v-list class="py-0">
    <notification v-for="notification in notifications" :key="notification.id" :notification="notification" />
    <div v-if="$apollo.queries.me.loading" class="d-flex justify-center py-2">
      <v-progress-circular dense class="py-0" indeterminate color="primary" />
    </div>
    <v-divider />
    <v-list-item>
      <v-btn color="secondary" text @click="readAllNotifications">{{ $t("notifications.read_all") }}</v-btn>
      <v-spacer></v-spacer>
      <v-btn
        :disabled="this.notifications.length >= totalNotifications"
        v-if="loadMore"
        color="primary"
        text
        @click="loadMoreNotifications"
        >{{ $t("notifications.view_more") }}
      </v-btn>
      <v-btn v-if="!loadMore" color="primary" text :to="{ name: 'Notifications' }"
        >{{ $t("notifications.view_more") }}
      </v-btn>
    </v-list-item>
  </v-list>
</template>

<script>
import gql from "graphql-tag";

import Notification, { NOTIFICATION_FRAGMENT } from "./Notification";
import moment from "moment";

export default {
  components: {
    Notification,
  },
  props: {
    loadMore: Boolean,
  },
  data() {
    return {
      me: null,
      page: 1,
    };
  },
  computed: {
    notifications() {
      return this.me?.notifications.data ?? [];
    },

    totalNotifications() {
      return this.me?.notifications.paginatorInfo.total ?? 0;
    },
  },
  apollo: {
    me: {
      query: gql`
        query MyNotifications($page: Int) {
          me {
            id
            unreadNotificationsCount
            notifications(page: $page) {
              data {
                id
                ...notification
              }
              paginatorInfo {
                total
              }
            }
          }
        }
        ${NOTIFICATION_FRAGMENT}
      `,
      variables: {
        page: 1,
      },
      subscribeToMore: {
        document: gql`
          subscription NewNotification {
            newNotification {
              id
              ...notification
            }
          }
          ${NOTIFICATION_FRAGMENT}
        `,
        updateQuery: (previousResult, { subscriptionData }) => {
          const { newNotification } = subscriptionData.data;

          if (previousResult.me.notifications.data.find((notification) => notification.id === newNotification.id))
            return previousResult;

          previousResult.me.notifications.data.unshift(newNotification);
          previousResult.me.notifications.paginatorInfo.total++;
          previousResult.me.unreadNotificationsCount++;
          return previousResult;
        },
      },
    },
  },
  methods: {
    loadMoreNotifications() {
      this.$apollo.queries.me.fetchMore({
        variables: {
          page: ++this.page,
        },
        updateQuery: (previousResult, { fetchMoreResult }) => {
          if (!fetchMoreResult) return previousResult;
          return {
            me: {
              ...previousResult.me,
              notifications: {
                ...previousResult.me.notifications,
                data: [...previousResult.me.notifications.data, ...fetchMoreResult.me.notifications.data],
              },
            },
          };
        },
      });
    },
    readAllNotifications() {
      this.$apollo.mutate({
        mutation: gql`
          mutation MarkAllNotificationsAsRead {
            markAllNotificationsAsRead {
              id
              unreadNotificationsCount
            }
          }
        `,
        update: (store) => {
          this.notifications.forEach((notification) => {
            store.writeFragment({
              id: `Notification:${notification.id}`,
              fragment: gql`
                fragment notificationRead on Notification {
                  __typename
                  id
                  read_at
                }
              `,
              data: {
                __typename: "Notification",
                id: notification.id,
                read_at: notification.read_at ?? moment().format("YYYY-MM-DD HH:mm:ss"),
              },
            });
          });
        },
        optimisticResponse: {
          markAllNotificationsAsRead: {
            __typename: "User",
            id: this.me.id,
            unreadNotificationsCount: 0,
          },
        },
      });
    },
  },
};
</script>
