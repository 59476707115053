import Vue from "vue";
import Vuetify from "vuetify/lib";

Vue.use(Vuetify);

import colors from "vuetify/lib/util/colors";
import WhatsappIcon from "../components/icons/WhatsappIcon.vue";

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: "#135094",
        secondary: "#4e5f7b",
        accent: "#2aa4c6",
        background: colors.grey.lighten3,
        warning: colors.orange.base,
        danger: "#ba1a1a",
      },
      dark: {
        primary: "#114885",
        secondary: "#4e5f7b",
        accent: "#2aa4c6",
        warning: colors.orange.darken3,
        danger: "#ba1a1a",
      },
    },
  },
  icons: {
    values: {
      whatsapp: {
        component: WhatsappIcon,
      },
    },
  },
});
