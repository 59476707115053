const urlB64ToUint8Array = (base64String) => {
  const padding = "=".repeat((4 - (base64String.length % 4)) % 4);
  const base64 = (base64String + padding).replace(/-/g, "+").replace(/_/g, "/");

  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
};

export const getSubscription = async () => {
  const sw = await navigator.serviceWorker.getRegistration();

  let key = null;
  let token = null;

  try {
    const applicationServerKey = urlB64ToUint8Array(process.env.VUE_APP_VAPID_PUBLIC_KEY);

    if (Notification.permission === "denied")
      return {
        subscriptionPayload: null,
        isSubscribed: false,
        alreadySubscribed: false,
      };

    // Registering push
    let subscription = await sw.pushManager.getSubscription();
    let alreadySubscribed = !(subscription === null);
    let isSubscribed = alreadySubscribed;

    if (!isSubscribed) {
      // Subcribe to push notification
      subscription = await sw.pushManager.subscribe({
        userVisibleOnly: true,
        applicationServerKey,
      });

      isSubscribed = true;
    }

    if (subscription) {
      key = subscription.getKey("p256dh");
      token = subscription.getKey("auth");

      key = key ? btoa(String.fromCharCode.apply(null, new Uint8Array(key))) : null;
      token = token ? btoa(String.fromCharCode.apply(null, new Uint8Array(token))) : null;
    }

    const supportedContentEncodings = PushManager.supportedContentEncodings;
    const contentEncoding = supportedContentEncodings ? supportedContentEncodings[0] : null;

    const subscriptionPayload = {
      endpoint: subscription.endpoint,
      key,
      token,
      contentEncoding,
    };

    return { subscriptionPayload, isSubscribed, alreadySubscribed };
  } catch (err) {
    return {
      subscriptionPayload: null,
      isSubscribed: false,
      alreadySubscribed: false,
    };
  }
};
