<template>
  <v-list-item :exact="false" @click="goToRoute" two-line :class="{ 'grey lighten-3': notification.read_at }">
    <v-list-item-content>
      <v-list-item-title class="text-body-2">
        {{ $t("notifications." + notification.type) }} -
        {{ moment(notification.created_at).fromNow() }}
      </v-list-item-title>
      <v-list-item-subtitle>{{ notification.data?.message }}</v-list-item-subtitle>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
import gql from "graphql-tag";
import moment from "moment";

export const NOTIFICATION_FRAGMENT = gql`
  fragment notification on Notification {
    id
    type
    data {
      model_id
      model_type
      message
    }
    read_at
    created_at
  }
`;

export default {
  name: "Notification",
  props: {
    notification: { Object, required: true },
  },
  computed: {
    toRoute() {
      switch (this.notification.type) {
        case "App\\Notifications\\AnnouncementCreatedNotification":
          return `/${this.$i18n.locale}/`;
        case "App\\Notifications\\NewMessageNotification":
          return `/${this.$i18n.locale}/chat/${this.notification.data.model_id}`;
        default:
          return "#";
      }
    },
  },
  methods: {
    moment,
    goToRoute() {
      if (!this.notification.read_at)
        this.$apollo.mutate({
          mutation: gql`
            mutation MarkNotificationAsRead($id: ID!) {
              markNotificationAsRead(id: $id) {
                id
                read_at
              }
            }
          `,
          variables: { id: this.notification.id },
          update: (store) => {
            const query = gql`
              {
                me {
                  id
                  unreadNotificationsCount
                }
              }
            `;

            const { me } = store.readQuery({
              query,
            });

            store.writeQuery({
              query,
              data: {
                me: {
                  ...me,
                  unreadNotificationsCount: me.unreadNotificationsCount - 1,
                },
              },
            });
          },
          optimisticResponse: {
            markNotificationAsRead: {
              ...this.notification,
              read_at: moment().format("YYYY-MM-DD HH:mm:ss"),
            },
          },
        });
      if (this.toRoute !== "#" && this.$route.path !== this.toRoute) this.$router.push(this.toRoute);
    },
  },
};
</script>
